import '../css/litegraph.css';

import Fragment from 'vue-fragment';
import Vue from 'vue';
import Vuelidate from 'vuelidate';

import { initializeApp } from 'firebase/app';
import { createProvider } from './vue-apollo';
import App from './App.vue';
import i18n from './i18n';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

import {
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_MESSAGING_ID,
  FIREBASE_PROJECT_ID,
} from './constants/env';

const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  projectId: FIREBASE_PROJECT_ID,
  messagingSenderId: FIREBASE_MESSAGING_ID,
  appId: FIREBASE_APP_ID,
};
Vue.prototype.$firebase = initializeApp(firebaseConfig);

Vue.use(Fragment.Plugin);
Vue.use(Vuelidate);

Vue.config.productionTip = false;

new Vue({
  apolloProvider: createProvider(),
  render: (h) => h(App),
  router,
  store,
  i18n,
  vuetify,
}).$mount('#app');
