/* eslint-disable import/no-cycle */
import fetchIntercept from 'fetch-intercept';

import { apolloClient } from '../vue-apollo';
import { AUTH_TOKEN } from '../constants/storage';
import { ERROR_CODES } from '../constants/errors';
import { isValidToken } from './validation';
import { removeData } from '../auth';
import REFRESH_TOKEN from '../graphql/Mutation/RefreshToken.gql';
import router from '../router';

const tokenRefresh = {
  time: null,
  ongoing: false,
};

// eslint-disable-next-line import/prefer-default-export
export const refreshToken = async (token = '') => {
  const { data } = await apolloClient.mutate({
    mutation: REFRESH_TOKEN,
    variables: { token },
  });

  const newToken = data.refreshToken;
  if (isValidToken(newToken)) localStorage.setItem(AUTH_TOKEN, newToken);
};

fetchIntercept.register({
  async response(response) {
    const token = localStorage.getItem(AUTH_TOKEN);
    const isExpired = JSON.parse(response.headers.get('isexpired'));

    // Remove session if the user gets an unauthorized code
    if (!response.ok && response.status === ERROR_CODES.unauthorized) {
      tokenRefresh.ongoing = false;
      tokenRefresh.time = null;

      removeData();
      router.push({ name: 'Login', query: { reset: true } });
      return response;
    }

    // Only refresh when:
    // 1. User has valid token
    // 2. Token has expired
    // 3. No refresh of the token is on going
    if (isValidToken(token) && isExpired && !tokenRefresh.ongoing) {
      tokenRefresh.ongoing = true;
      tokenRefresh.time = Date.now();

      const { data } = await apolloClient.mutate({
        mutation: REFRESH_TOKEN,
        variables: { token },
      });

      const newToken = data.refreshToken;
      if (isValidToken(newToken)) localStorage.setItem(AUTH_TOKEN, newToken);

      tokenRefresh.ongoing = false;
      tokenRefresh.time = null;
    }

    return response;
  },
});
