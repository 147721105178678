import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const loadView = (name) => {
  return () => import(`../views/${name}.vue`);
};

const routes = [
  {
    path: '/login/:tenantUrl?',
    name: 'Login',
    component: loadView('Login'),
  },
  {
    path: '/main',
    name: 'Main',
    component: loadView('MainPage'),
  },
  {
    path: '/',
    redirect: () => {
      return { path: '/login/:tenantUrl?' };
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
