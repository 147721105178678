/* eslint-disable import/no-cycle */
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import Vue from 'vue';
import VueApollo from 'vue-apollo';

import { authMiddleware, removeData } from './auth';
import { GATEWAY_HTTP } from './constants/env';
import { AUTH_TOKEN } from './constants/storage';

// Install the vue plugin
Vue.use(VueApollo);

// Http endpoint
const httpEndpoint = `${GATEWAY_HTTP}/graphql`;
// Files URL root
export const filesRoot = httpEndpoint.substr(0, httpEndpoint.indexOf('/graphql'));

Vue.prototype.$filesRoot = filesRoot;
const cache = new InMemoryCache();

// Config
const defaultOptions = {
  // uri: httpEndpoint,
  link: authMiddleware,
  cache,
  // cache,
  // httpEndpoint,
  // link: authMiddleware,
  // persisting: false,
  // ssr: false,
  // tokenName: AUTH_TOKEN,
  // websocketsOnly: false,
  // wsEndpoint: null,
};

export const apolloClient = new ApolloClient(defaultOptions);

// Call this in the Vue app file
export function createProvider() {
  // Create apollo client
  // apolloClient.wsClient = apolloClientService.wsClient;

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
      },
      $mutate: {
        errorPolicy: 'all',
      },
    },
  });

  return apolloProvider;
}

// Manually call this when user log in
export async function onLogin(token) {
  localStorage.setItem(AUTH_TOKEN, token);
  try {
    await apolloClient.resetStore();
  } catch (e) {
    throw new Error(`%cError on cache reset (login):  ${e.message}`);
  }
}

// Manually call this when user log out
export async function onLogout() {
  removeData();

  apolloClient.stop();
  await apolloClient.resetStore();
}
