import moment from 'moment';

import { EMPTY_VALUES } from '../constants/validation';
import { INPUT_DATE_FORMAT } from '../constants/moment';
import { IS_INPUT_DATE } from '../constants/regex';

export const isValidJSON = (value) => {
  try {
    return !!JSON.parse(value);
  } catch (e) {
    return false;
  }
};

export const JSONize = (str) =>
  str
    // wrap keys without quote with valid double quote
    .replace(/([$\w]+)\s*:/g, (_, $1) => `"${$1}":`)
    // replacing single quote wrapped ones to double quote
    .replace(/'([^']+)'/g, (_, $1) => `"${$1}"`);

export const isValidJSONorObj = (str) => isValidJSON(str) || isValidJSON(JSONize(str));

// Returns an object with the key "__typename" removed from itself and its children objects
export const sanitizeGraphql = (obj) => {
  const copy = { ...obj };
  // eslint-disable-next-line  no-underscore-dangle
  delete copy.__typename;

  Object.keys(copy).forEach((key) => {
    if (copy[key] && typeof copy[key] === 'object' && !Array.isArray(copy[key])) {
      copy[key] = sanitizeGraphql(copy[key]);
    }
  });

  return copy;
};
const parseJwt = (token) => {
  const base64Url = token?.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export const isValidToken = (token) => {
  try {
    const { exp } = parseJwt(token);
    const now = moment().unix();
    const isExpired = exp < now;
    return token && !['undefined', 'null'].includes(token) && !isExpired;
  } catch (e) {
    return false;
  }
};

export const isObj = (value) => !!(value && typeof value === 'object' && !Array.isArray(value));

export const cleanEmptyKeys = (obj = {}) => {
  const copy = { ...obj };

  Object.entries(copy).forEach(([key, value]) => {
    // If it has another object, clean that object
    if (isObj(value)) {
      copy[key] = cleanEmptyKeys(copy[key]);
      return;
    }

    // Remove empty values
    if (EMPTY_VALUES.includes(value)) {
      delete copy[key];
    }
  });

  return copy;
};

export const safeJSONObjParse = (string) => (isValidJSON(string) ? JSON.parse(string) : {}) || {};

export const deepClone = (value) => JSON.parse(JSON.stringify(value));

export const isValidPastDate = (date = '') =>
  date &&
  typeof date === 'string' &&
  IS_INPUT_DATE.test(date) &&
  moment(date, INPUT_DATE_FORMAT).isBetween('1900-01-01', undefined, undefined, '[]');

export const objectHasValue = (obj) =>
  !!(isObj(obj) && Object.keys(obj).length && Object.values(obj).some(Boolean));
