const {
  NODE_ENV,
  VUE_APP_FIREBASE_API_KEY: FIREBASE_API_KEY,
  VUE_APP_FIREBASE_APP_ID: FIREBASE_APP_ID,
  VUE_APP_FIREBASE_AUTH_DOMAIN: FIREBASE_AUTH_DOMAIN,
  VUE_APP_FIREBASE_MESSAGING_SENDER_ID: FIREBASE_MESSAGING_ID,
  VUE_APP_FIREBASE_PROJECT_ID: FIREBASE_PROJECT_ID,
  VUE_APP_GATEWAY_HTTP: GATEWAY_HTTP,
} = process.env;

export {
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_MESSAGING_ID,
  FIREBASE_PROJECT_ID,
  GATEWAY_HTTP,
  NODE_ENV,
};
