<template>
  <v-app>
    <nav-bar v-if="isLoggedIn" />
    <notification-snack></notification-snack>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<style lang="scss">
main {
  overflow: hidden;
}

body,
.v-application {
  font-family: 'Montserrat', sans-serif !important;
  -ms-overflow-style: none; /* Hide scroll on IE and Edge */
  scrollbar-width: none; /* Hide scroll on Firefox */
  &::-webkit-scrollbar {
    /* Hide scroll on Chrome, Safari and Opera */
    display: none;
  }
}
</style>

<script>
import NavBar from './components/NavBar.vue';
import NotificationSnack from './components/NotificationSnack.vue';
import { WHITELIST_LOGIN } from './constants/routes';
import { AUTH_TOKEN } from './constants/storage';
import { refreshToken } from './utils/fetch-intercept';

export default {
  name: 'App',
  components: { NavBar, NotificationSnack },
  data() {
    return {
      refreshTokenInterval: null,
      refreshTokenIntervalSeconds: 300,
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    routeGuard({ name, query, params }) {
      const { isLoggedIn } = this;
      const tenantUrl = params?.tenantUrl || this.$store.state.user?.tenantUrl;

      if (query.reset) {
        this.$router.push({
          name: 'Login',
          params: { tenantUrl },
        });
        this.$store.commit('resetStore');
        this.$store.dispatch('setNotification', {
          color: 'error',
          text: 'Session expired.',
          timeout: 3000,
        });
        return;
      }

      // Not logged in
      if (!isLoggedIn && !WHITELIST_LOGIN.includes(name)) {
        this.$router.push({
          name: 'Login',
          params: { tenantUrl },
        });
        this.$store.commit('resetStore');
        return;
      }

      // Redirect to main page
      if (isLoggedIn && WHITELIST_LOGIN.includes(name)) {
        this.$router.history.push({ name: 'Main' });
      }
    },
  },
  watch: {
    $route: {
      // immediate: true,
      handler(route) {
        this.routeGuard(route);
      },
    },
    isLoggedIn: {
      immediate: true,
      handler(val) {
        if (!val) return;
        this.refreshTokenInterval = setInterval(() => {
          refreshToken(localStorage.getItem(AUTH_TOKEN));
        }, this.refreshTokenIntervalSeconds * 1000);
      },
    },
  },
};
</script>
