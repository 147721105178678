import createLogger from 'vuex/dist/logger';
import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';
import { isValidToken } from '../utils/validation';

import { NODE_ENV } from '../constants/env';
import { VUEX_STORE, EXCLUDE_KEYS, AUTH_TOKEN } from '../constants/storage';
import { apolloClient } from '../vue-apollo';

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: VUEX_STORE,
  storage: window.localStorage,
  reducer: (state) => {
    const copy = { ...state };

    EXCLUDE_KEYS.forEach((k) => {
      delete copy[k];
    });

    return copy;
  },
});

const defaultNotification = {
  color: '',
  showing: false,
  text: '',
  timeout: -1,
};
const defaultWorkflowEditorState = () => {
  return {
    open: false,
    item: null,
  };
};

const defaultState = () => {
  return {
    dialogs: {
      workflowEditor: defaultWorkflowEditorState(),
      saveDialog: false,
    },
    notification: { ...defaultNotification },
    refreshWorkflows: false,
    refreshWorkflowVersions: false,
    user: {
      id: null,
      primaryRole: null,
      providerId: null,
      tenantUrl: null,
    },
    activeWorkflow: {},
    activeWorkflowVersion: {},
    workflows: [],
  };
};

export default new Vuex.Store({
  state: defaultState(),
  getters: {
    isLoggedIn: ({ user }) => !!user?.id && !!isValidToken(localStorage.getItem(AUTH_TOKEN)),
    tenantUrl: ({ user }) => user.tenantUrl,
  },
  mutations: {
    toggleDialog(state, { dialog, open }) {
      state.dialogs[dialog] = open;
    },
    toggleWorkflowEditor(state, editorState) {
      const newState = Object.assign(defaultWorkflowEditorState(), editorState);
      Object.assign(state.dialogs.workflowEditor, newState);
    },
    resetStore(state) {
      Object.assign(state, defaultState());
    },
    saveUser(state, updates) {
      Object.assign(state.user, updates);
    },
    set(state, updates) {
      Object.assign(state, updates);
    },
    setActiveWorkflow(state, workflow) {
      Vue.set(state, 'activeWorkflow', workflow);
    },
    setActiveWorkflowVersion(state, workflow) {
      Vue.set(state, 'activeWorkflowVersion', workflow);
    },
  },
  actions: {
    async getAndSaveUser({ commit }, tenantUrl) {
      const result = await apolloClient.query({
        query: await import('../graphql/Query/GetCurrentUser.gql'),
        fetchPolicy: 'no-cache',
      });
      const { getCurrentUser: user } = result.data;
      commit('set', {
        user: {
          ...user,
          tenantUrl,
          providerId: user.primaryRole === 'provider' ? user.id : user.providerId,
        },
      });
    },
    setNotification({ commit }, notification) {
      commit('set', {
        notification: {
          ...defaultNotification,
          ...notification,
          showing: true,
        },
      });
    },
  },
  plugins: [vuexLocalStorage.plugin, ...(NODE_ENV === 'development' ? [createLogger()] : [])],
});
