/* eslint-disable import/no-cycle */
import { ApolloLink } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { logErrorMessages } from '@vue/apollo-util';
// import { createPersistedQueryLink } from 'apollo-link-persisted-queries';

import { AUTH_TOKEN, VUEX_STORE } from './constants/storage';
import { ERROR_CODES } from './constants/errors';
import { isValidToken } from './utils/validation';
import { NODE_ENV, GATEWAY_HTTP } from './constants/env';
// import router from './router';

import { refreshToken } from './utils/fetch-intercept';

export const removeData = () => {
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem(VUEX_STORE);
};
const httpEndpoint = `${GATEWAY_HTTP}/graphql`;

const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: httpEndpoint,
});

const auth = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const token = localStorage.getItem(AUTH_TOKEN);
  const headers = {};

  if (isValidToken(token)) headers.authorization = `Bearer ${token}`;

  operation.setContext({
    headers,
  });

  return forward(operation);
});

const errorLink = onError(({ networkError, graphQLErrors }) => {
  const unauthenticatedError =
    graphQLErrors &&
    graphQLErrors[0] &&
    graphQLErrors[0].extensions?.exception?.message ===
      'User does not have provider access to resource.';
  const authenticationNetworkError = networkError?.statusCode === ERROR_CODES.unauthorized;
  if (unauthenticatedError || authenticationNetworkError) {
    const token = localStorage.getItem(AUTH_TOKEN);
    refreshToken(token);
    // router.push({ name: 'Login', query: { reset: true } });
    // removeData();
  }

  if (NODE_ENV !== 'production') {
    logErrorMessages({ graphQLErrors }, false);
  }
});

// export const authMiddleware = createPersistedQueryLink()
//   .concat(errorLink)
//   .concat(httpLink);

export const authMiddleware = auth.concat(errorLink).concat(httpLink);
