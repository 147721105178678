<template>
  <v-app-bar app clipped-left color="primary" dark>
    <!-- <v-app-bar-nav-icon> </v-app-bar-nav-icon> -->

    <v-toolbar-title>
      <span class="ml-2">
        Sentry Workflows
      </span>
    </v-toolbar-title>

    <v-menu min-width="290px" offset-y bottom transition="scroll-y-transition" v-model="menu">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-if="noTenant" v-bind="attrs" v-on="on"
          ><v-icon>mdi-chevron-down</v-icon></v-btn
        >
      </template>
      <v-list>
        <v-list-item-group v-model="menuSelection">
          <v-list-item @click="selectProvider">
            <v-list-item-icon>
              <v-icon>mdi-domain</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              Select Provider
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <v-spacer />

    <div class="d-flex flex-nowrap">
      <!-- <span class="text-capitalize d-flex align-center"></span> -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="logout" v-on="on" v-bind="attrs" text>
            <v-icon>mdi-exit-to-app</v-icon><span class="ml-2">Logout</span>
          </v-btn>
        </template>
        <span>
          Logout
        </span>
      </v-tooltip>
    </div>
  </v-app-bar>
</template>

<style lang="scss">
.app-bar {
  z-index: 10;
}
.nav-bar__user-name__container {
  max-width: 375px;
  height: 100%;
  .nav-bar__user-name-wrap {
    overflow: hidden;
    .nav-bar__user-name {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>

<script>
import { onLogout } from '../vue-apollo';

export default {
  name: 'NavBar',
  data() {
    return {
      menu: false,
      menuSelection: null,
    };
  },
  computed: {
    noTenant() {
      return !this.$store.state.user.tenantUrl;
    },
  },
  methods: {
    async logout() {
      const { tenantUrl } = this.$store.state.user;
      await this.$store.commit('resetStore');
      await onLogout();
      this.$router.push({
        name: 'Login',
        params: { tenantUrl },
      });
    },
    selectProvider() {
      this.$store.commit('saveUser', { providerId: null });
    },
  },
};
</script>
